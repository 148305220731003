@use '@material/select/_index' as select;
@use '@material/textfield';
@import 'styles/variables';

#contact {

    .skeleton-loading {
        display: flex;
        margin-top: $regularPad;
        background-color: $ghost-white !important;

        @media (max-width: $desktop) {
            margin-top: 25px;
        }

        &.title-contact {
            width: 230px;
            height: 48px;

            @media (max-width: $desktop) {
                margin-left: 0;
                width: 100px;
                margin-bottom: unset;
            }
        }

        &.first-subtitle-1 {
            width: 545px;
            height: 19px;

            @media (max-width: 576px) {
                margin-left: 0px;
                width: 330px;
                height: 63px;
            }
        }

        &.contact-form-1 {
            width: 630px;
            height: 800px;
        }

        &.banner-1 {
            margin-top: 50px;
        }

        &.title-1 {
            width: 200px;
            height: 36px;
        }

        &.paragraph-1 {
            width: 606px;
            height: 64px;

            @media (max-width: 1200px) {
                width: 530px;
            }

            @media (max-width: 576px) {
                margin-left: 0px;
                width: 330px;
                height: 96px;
            }
        }
    }

    font-family: $primaryfont;
    padding-top: 60px;

    .cx-container {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
        max-width: 1236px;

        @media (max-width: $widescreen) {
            max-width: 537px;
        }

        @media (max-width: $phone) {
            max-width: 335px;
        }

        @media (max-width: $smallphone) {
            max-width: 240px;
        }

        @media ($quadhdscreen <=width) {
            max-width: 1436px;
        }
    }

    .cx-contact-header {
        margin-top: 100px;

        @media (max-width: $widescreen) {
            margin-top: 42px;
        }

        @media (max-width: $phone) {
            margin-top: 18.53px;
        }
    }

    .cursor-default {
        cursor: default;
    }

    .cx-header-main-text p {
        letter-spacing: 1.28px;
        line-height: 47px;
        margin-bottom: $small;

        @media (max-width: $widescreen) {
            letter-spacing: 1.28px;
            line-height: 21px;
        }
    }

    .cx-header-second-text p {
        letter-spacing: 0.512px;
        line-height: 32px;
        height: 63px;

        @media (max-width: $widescreen) {
            letter-spacing: 0.512px;
            line-height: $huge;
        }
    }

    .cx-contact-title {
        & p {
            letter-spacing: 0.768px;
            line-height: 80px;

            @media (max-width: $widescreen) {
                letter-spacing: 0.768px;
                line-height: $large;
                padding-top: 40px;
                padding-bottom: 20px;
            }

            @media (max-width: $phone) {
                letter-spacing: 0.768px;
                line-height: $large;
                padding-top: 30px;
                padding-bottom: 8px;
            }
        }
    }

    .card {
        background-color: $white;
        border-radius: $small;
        filter: drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.08));
        height: 207px;
        width: 294px;
        transition: 0.3s;

        &:hover {
            transform: scale(1.02);
            border: 1px solid $purple-text !important;
        }

        &:active {
            background: $cx-purple-gradient;
            color: $white;
        }

        @media (max-width: $widescreen) {
            height: 158px;
            width: 258px;
        }

        @media (max-width: $phone) {
            height: 158px;
            width: 100%;
        }

        @media ($quadhdscreen <=width) {
            width: 344px;
        }
    }

    .cx-group-cards {
        max-width: inherit;
        margin-top: 31px;
        margin-bottom: 31px;

        @media (max-width: $widescreen) {
            height: 331px;
        }

        @media (max-width: $phone) {
            height: 677px;
        }
    }

    .cx-contact-banner {
        margin-bottom: 30px;

        & img {
            position: relative;
            width: 100%;

            @media (max-width: $widescreen) {
                left: -210px;
                max-height: inherit;
                width: auto;
            }

            @media (max-width: $phone) {
                left: -88px;
                max-height: inherit;
                width: auto;
            }

            @media (max-width: $smallphone) {
                left: -148px;
                max-height: inherit;
                width: auto;
            }
        }

        @media only screen and (max-width: $widescreen) {
            max-height: 214px;
            overflow: hidden;
        }

        @media only screen and (max-width: $phone) {
            max-height: 164px;
            overflow: hidden;
        }
    }

    .cx-contact-container {
        margin-bottom: 72px;
    }

    .cx-contact-text {
        padding-bottom: 280px;

        & p:last-child {
            margin-bottom: 0;
        }

        @media (max-width: $widescreen) {
            padding-bottom: 40px;
        }

        @media (max-width: $phone) {
            padding-bottom: 88px;
        }
    }

    .cx-contact-paragraph {
        line-height: 32px;
        letter-spacing: 0.512px;
        color: $mediumblack;

        &:not(:last-of-type) {
            & p {
                margin-bottom: 32px !important;
            }
        }
    }

    .cx-contact-form {
        background-color: white;
        border-radius: 4px;
        padding-left: 78px;
        padding-right: 78px;

        @media (max-width: $widescreen) {
            padding-left: 50px;
            padding-right: 50px;
        }

        @media (max-width: $phone) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media (max-width: $smallphone) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .cx-label-form {
        color: $mediumblack;
        line-height: 16px;
        letter-spacing: 0.133px;
    }

    .form-control {
        &:focus {
            box-shadow: none;
        }
    }

    .cx-select {
        @include select.outline-color(('default': $transparent, 'hover': $purplehover, 'focus': $purple-text));
        @include select.outline-shape-radius(4px);
        border-radius: 4px;
        position: relative;
        @include select.ink-color($input-placeholder);

        &:hover {
            @include select.ink-color($input-placeholder-hover);
        }

        &.mdc-select--focused {
            @include select.ink-color($black);
        }
    }

    .cx-select-options {
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.160704);
    }

    .mdc-select__menu {
        left: auto !important;
        right: 0 !important;
        position: absolute !important;
        width: 219px;

        @media (width <=$tablet) {
            width: 100%;
        }
    }

    .mdc-list-item {
        background-color: $transparent;

        &:hover {
            background-color: $lilac-hover;
            border-radius: 4px;

            .mdc-list-item__primary-text {
                color: $darkergray;
            }
        }

        .mdc-list-item__primary-text {
            letter-spacing: 0.68px;
            font-weight: 400;
            color: $darkergray-default;
            font-size: inherit;
            font-family: inherit;
        }
    }

    .btn-check:focus+.btn,
    .btn:focus {
        box-shadow: none;
    }

    .btn-send {
        background: $cx-purple;
        margin-top: $largeplus;
        border-radius: 6px;
        padding-left: 61.51px;
        padding-right: 62.52px;
        margin-bottom: 126px;
        white-space: nowrap;
        width: 294px;
        height: 50px;

        & span:nth-child(2) {
            font-weight: 500;
            font-size: $medium;
            line-height: 19px;
        }

        &:hover {
            background: $cx-primary-hover;
        }

        @media (max-width: $widescreen) {
            margin-bottom: 3.75rem;
        }

        @media (max-width: $smallphone) {
            width: 220px;
        }
    }

    #input-email {
        &>input {
            padding-right: 20px;
        }
    }

    .link-support {
        color: $cx-purple;

        &:hover {
            text-decoration: underline !important;
        }
    }

    .mdc-circular-progress__determinate-circle,
    .mdc-circular-progress__indeterminate-circle-graphic {
        stroke: $cx-blue;
    }

    .circle-icon {
        right: 12px;
    }

    .mini-spinner-icon {
        right: 10px;
    }

    .cx-spinner-icon {
        margin-top: $largeplus;
        margin-bottom: 126px;

        @media (max-width: $widescreen) {
            margin-bottom: 3.75rem;
        }
    }

    .cx-drop-zone-outside {
        position: relative;
        flex-direction: column;

        .mdc-text-field__resizer {
            resize: none;
            z-index: 5;
        }

        #drop-zone {
            display: none;
            height: 90%;
            width: calc(100% - 20px);
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            align-items: center;
            justify-content: center;
            z-index: -1;

            @media only screen and (max-width: $widescreen) {
                margin-top: 0.25rem;
                border: none;
                align-content: end;
                height: 20px;
                display: contents;
            }
        }

        .wrapper-sm {
            height: 25px;
        }

        .drop-zone-lg {
            display: flex;

            &>div {
                color: $purple-text;
            }

            @media only screen and (max-width: $widescreen) {
                display: none;
            }
        }

        @media (min-width: $widescreen) {
            &:hover {
                .drop-zone-sm label {
                    display: flex;
                }
            }
        }

        @media only screen and (max-width: $widescreen) {
            .drop-zone-sm label {
                display: flex;
            }
        }
    }

    .drop-zone-sm {
        min-width: 25px;

        & label {
            display: none;
        }
    }

    .hover-drop-zone {
        background-color: rgba(49, 15, 162, 0.05) !important;
        z-index: 1;

        textarea {
            color: $transparent;
        }

        #wrapper-sm {
            display: none !important;
        }

        #drop-zone {
            border: 1px dashed $purple-text;
            display: flex;
        }
    }

    .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 3px;
        margin-left: 3px;
    }

    .mdc-list-item__content .file-name {
        @extend .file-name;
    }

    #wrapper-sm {
        & .cx-first-files-list {
            width: 90%;

            & .mdc-list {
                list-style-type: none;
                display: flex;

                & .mdc-list-item {
                    padding-left: 0;
                    max-width: max-content;
                    flex: 1 1 0;

                    &.mdc-list-item--with-one-line {
                        float: left;
                        height: auto;

                        &:hover {
                            background-color: $lilac-hover;
                            border-radius: 4px;
                        }
                    }
                }
            }

            & .mdc-list-item__content {
                pointer-events: auto;
            }
        }

        & .cx-dropdown-files-list {
            overflow: auto;
            max-height: 200px;
            max-width: 15rem;
            padding: 0.25rem;
            transform: translate(0, 25px) !important;
            inset: 0 0 auto auto !important;

            & .mdc-list-item {
                padding-left: 0;

                &.mdc-list-item--with-one-line {
                    height: auto;

                    &:hover {
                        background-color: $lilac-hover;
                        border-radius: 4px;
                    }
                }
            }

            & .mdc-list-item__content {
                pointer-events: auto;
                max-width: 170px;
            }

            &::-webkit-scrollbar {
                width: 12px;
            }

            &::-webkit-scrollbar-track {
                background-color: $white;
            }

            &::-webkit-scrollbar-thumb {
                background-clip: content-box;
                background-color: $purple-text;
                border: 4px solid $white;
                border-radius: $largeplus;
            }
        }

        div.dropend {
            width: 25px;
        }
    }

    textarea::-webkit-scrollbar {
        width: 20px;
    }

    textarea::-webkit-scrollbar-thumb {
        background-clip: content-box;
        background-color: rgba(176, 176, 176, 0.5);
        border: 7px solid transparent;
        border-radius: 20px;

        &:hover {
            background-color: rgba(176, 176, 176, 0.6);
        }

        &:active {
            background-color: rgba(176, 176, 176, 0.7);
        }
    }

    .visit-faq-btn-content {
        width: 139px;
        margin-right: auto;
    }

    .visit-faq-btn {
        background: $cx-purple !important;
        min-width: 139px;
        height: 50px !important;
        margin-top: 20px;

        &:hover {
            background: $cx-primary-hover !important;
        }
    }

    .cx-form-control.message-area {
        @include textfield.hover-outline-color($transparent);
        border: 1px solid $inputgray;

        &:hover {
            border: 1px solid $purplehover;
        }

        &.active {
            border: 1px solid $purple-text !important;
        }

        .mdc-text-field--focused {
            @include textfield.ink-color($black);
        }
    }
}