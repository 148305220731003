@import 'styles/variables';
@import 'styles/eeta-page-style';

#cookies {

    .text-content:not(:last-child)::after {
        content: "";
        display: block;
        width: 100%;
        margin: 0px auto;
        height: 0px !important;
        background-color: rgb(231, 231, 231);
    }

    .legal-page-skeleton {
        border-top: unset !important;
    }

    .title-container {
        color: $tooltip-primary;
    }

    .styles-text {

        p {
            margin-bottom: 40px !important;
        }
    }

    &.skeleton-policy::after {
        background-color: unset !important;
    }

    &.skeleton-policy {

        .header {
            border-block: none;
        }
    }

    .skeleton-loading {
        display: flex;
        margin-top: $regularPad;
        background-color: $ghost-white !important;

        @media (max-width: $desktop) {
            margin-top: 25px;
        }

        &.title-cookies {
            width: 230px;
            margin-left: 0px;
            height: 35px;

            @media (max-width: $desktop) {
                margin-left: 0;
                width: 100px;
                margin-bottom: unset;
            }

            @media (max-width: 800px) {
                margin-left: 0;
                width: 100px;
                margin-top: 10px;
            }

            @media (max-width: 768px) {
                margin-left: 0;
                width: 100px;
                margin-top: 100px;
            }
        }

        &.text-content-first {
            height: 24px;
            width: 280px;
            margin-top: $regularPad;
            margin-left: auto;
        }

        &.summary-title {
            width: 0px;
            height: 0px;
        }

        &.summary-content {
            font-size: $small;
            margin-top: $regularPad;
            height: 276px;

            @media (max-width: $phone) {
                height: 130px;
            }
        }

        &.example-title {
            font-weight: bold;
            width: 180px;
            height: 30px;
        }

        &.session-content {
            font-size: $small;
            padding: 10px;
            height: 128px;
        }

        &.preference-title {
            font-weight: bold;
            width: 200px;
            height: 30px;
        }

        &.preference-content {
            font-size: $small;
            padding: 15px;
            height: 32px;
        }

        &.security-title {
            font-weight: bold;
            width: 200px;
            height: 30px;
        }

        &.security-content {
            font-size: $small;
            margin-top: $regularPad;
            height: 30px;

            @media (max-width: $phone) {
                height: 120px;
            }
        }

        &.adversiting-title {
            font-weight: bold;
            width: 220px;
            height: 30px;
        }

        &.adversiting-content {
            font-size: $small;
            margin-top: $regularPad;
            height: 296px;

            @media (max-width: $phone) {
                height: 130px;
            }
        }
    }
}